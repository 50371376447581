import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import * as DesignSystem from 'src/DesignSystem';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import SEO from 'src/components/SEO';
import Box from 'src/components/Box';
import Tag from 'src/components/Tag';
import Topography from 'src/images/topography.svg';

interface Props {
  data: {
    mdx: {
      body: string;
      frontmatter: {
        title: string;
        subtitle: string;
      };
    };
  };
}

export default function ArticleLayout(props: Props): JSX.Element {
  const mdx = props.data.mdx;

  return (
    <BasicLayout>
      <SEO title={mdx.frontmatter.title} />
      <div className="max-w-6xl p-4 mx-auto text-gray-900 relative">
        <img src={Topography} className="absolute -z-10 inset-x-0 px-8 -mt-4" />
        <div className="uppercase tracking-widest text-purple-700 md:text-xl font-bold md:mt-16">
          {mdx.frontmatter.subtitle}
        </div>
        <h1 className="font-bold text-3xl sm:text-4xl md:text-6xl mb-4 md:mb-16">{mdx.frontmatter.title}</h1>
        <MDXProvider
          components={{
            Box,
            Tag,
            p: DesignSystem.Paragraph,
            h1: DesignSystem.H1,
            h2: DesignSystem.H2,
            h3: DesignSystem.H3,
            h4: DesignSystem.H4,
            h5: DesignSystem.H5,
            h6: DesignSystem.H6,
            strong: DesignSystem.Strong,
            a: DesignSystem.A,
            table: DesignSystem.Table,
            th: DesignSystem.Th,
            td: DesignSystem.Td,
            blockquote: DesignSystem.Blockquote,
            li: DesignSystem.Li,
            ul: DesignSystem.Ul,
          }}
        >
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </BasicLayout>
  );
}

export const pageQuery = graphql`
  query AdventureQuery($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
      }
    }
  }
`;
